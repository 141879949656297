import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, split } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
// import fetch from 'node-fetch';

const wsUrl =
  process.env.NODE_ENV === 'production'
    ? `${process.env.REACT_APP_PRODUCTION_SOCKET_URL}/subscriptions`
    : `${process.env.REACT_APP_DEVELOPMENT_SOCKET_URL}/subscriptions`;
const wsLink = new WebSocketLink({
  uri: wsUrl,
  options: {
    reconnect: true,
  },
});

const httpLink = new HttpLink({
  uri: `${
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_PRODUCTION_API_URL
      : process.env.REACT_APP_DEVELOPMENT_API_URL
  }/graphql`,
  credentials: 'include',
  // fetch: fetch,
  // credentials: 'same-origin',
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

interface Definition {
  kind: string;
  operation?: string;
}

const link = split(
  ({ query }) => {
    const { kind, operation }: Definition = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  ApolloLink.from([errorLink, httpLink])
);
const cache = new InMemoryCache();

const apollo = new ApolloClient({
  link,
  cache,
});

export default apollo;
