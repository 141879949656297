import { io } from 'socket.io-client';

const socket = io(
  process.env.NODE_ENV === 'production'
    ? `${process.env.REACT_APP_PRODUCTION_SOCKET_URL}`
    : `${process.env.REACT_APP_DEVELOPMENT_SOCKET_URL}`
);

socket.on('connect', () => {
  console.log(socket.id);
});

export default socket;
